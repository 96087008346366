@tailwind base;

@tailwind components;

@tailwind utilities;

@media (min-width: 768px) {
    .divide-x-pipe > li + li::before {
        content: "|";
    }
}

.bg-ec {
    background-image: url('images/ec.png');
}

.bg-header {
    background-image: url('images/header-bg.jpg');
}

#header-content {
    /* min-height: 290px; */
    background-image: url('images/header-bg-xs.png');
    background-position: 0.25rem 2rem;
    background-size: auto 210px;
    padding-left: 4.25rem;
}

#header-content ul.header-content {
    padding-right: 5px;
}

@media (min-width: 768px) {
  #header-content {
    background-image: url('images/header-bg.png');
    background-position: 0.25rem bottom;
    background-size: 6.5rem auto;
    padding-left: 7rem;
  }
}

#header-content h1, #header-content ul.header-content {
    /* padding-left: 95px; */
}

@media (min-width: 1024px) {
    #header-content {
      background-position: 2% bottom;
      background-size: auto 80%;
      padding-left: 7.5%;
    }
    #header-content h1 {
        padding-left: initial;
    }
    #header-content ul.header-content {
        /* padding-left: 160px; */
    }
}

@media (min-width: 1280px) {
    #header-content {
      background-position: 2% bottom;
      background-size: auto 90%;
      padding-left: 7.5%;
    }
    #header-content h1 {
        padding-left: initial;
    }
    #header-content ul.header-content {
        /* padding-left: 160px; */
    }
}

.arrowed-list {
    position: relative;
}

.arrowed-list li::before {
    @apply text-arrowtown;
    content: ">";
    font-weight: 800;
    position: absolute;
    left: 0;
}

.link {
    height: 70%;
    width: 5px;
    border-left: 5px dotted #99f3da;
    position: absolute;
    top: 80px;
    left: 50%;
    xtransform: translate(-50%, -50%);
    z-index: -1;
}

@media (min-width: 1024px) {
    .link {
        width: 80%;
        height: 5px;
        border-bottom: 5px dotted #fff;
        position: absolute;
        top: 133px;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
}

div {
    z-index: 0;
}

p.initial-letter::first-letter {
    font-size: 3.2rem !important;
    float: left;
    padding: 0;
    letter-spacing: 0;
    margin-right: 4px;
    margin-bottom: 1px;
    line-height: 3.2rem;
    font-weight: 400;
}

.quoted {
    position: relative;
}

.quoted::before {
    content: "";
    width: 38px;
    height: 30px;
    background-image: url('images/quote-start.png');
    position: relative;
    display: inline-block;
    left: -20px;
}

.quoted::after {
    content: "";
    width: 37px;
    height: 30px;
    background-image: url('images/quote-end.png');
    position: relative;
    display: inline-block;
    right: -20px;
    bottom: -20px;
}

.h-15 {
    height: 15rem;
}

.w-15 {
    width: 15rem;
}

.-indent-2 {
    position: relative;
    left: -2rem;
}

.right-1 {
    right: 1rem;
}

.bottom-1 {
    bottom: 1rem;
}

.underline-dotted {
    position: relative;
}

.underline-dotted::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 105%;
    border-bottom: 5px dotted #258569;
}

.popup-hidden {
  display: none;
}

.hamburger {
  border-color: rgba(0,0,0,.1);
  border-radius: .25rem;
  padding: .25rem .75rem;
}

#sticked-fill {
    display: none;
}

@media (min-width: 768px) {
    #sticked-fill {
        display: block;
    }

    .sticked {
        position: fixed;
        top: 0;
        z-index: 20;
        left: 0;
        right: 0;
    }

    .scrolled {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
        transition: all 1s ease-in;
    }

}

/* #sticked-container { */
/*     z-index: 10; */
/*     background: #fff; */
/*     width: 100%; */
/*     left: 0; */
/*     right: 0; */
/* } */

/* xsection#menu { */
/*     z-index: 10; */
/* } */

/* xsection#menu.scrolled { */
/*     @apply shadow-2xl; */
/*     border-bottom: 0px; */
/* } */

/* .sticked-active { */
/*     position: fixed!important; */
/*     top: 0; */
/*     box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1); */
/*     /\* transition: all 1s ease-in; *\/ */
/* } */
/* } */
